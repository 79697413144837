import React from "react"
import { Container, Row, Col } from "reactstrap"
import Layout from "../containers/Layout"
import SEO from "../components/seo"

const CopyrightsPage = () =>
    <Layout>
        <SEO title="Copyrights" />
        <div className="main">
            <div className="section text-center">
                <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
                    <Container>
                        <Row >
                            <h3 className="mt-0">حقوق الطبع والنشر</h3>
                        </Row>
                    </Container>
                </div>
                <br />
                <Container style={{ textAlign: "right" }}>
                    <Row>
                        <Col>
                            <p>جميع المحتويات المتوفرة في البوابة البيئية الخليجية بما في ذلك المعلومات والبرمجيات والخرائط محمية بموجب حقوق النشر والعلامات التجارية وأشكال حقوق الملكية الأخرى.</p>
                            <p> ويُسمح فقط للمستخدم الشخصي وللاستخدام غير الربحي بالاستفادة من محتوى البوابة وأية معلومات منشورة عليها مع ضرورة الإشارة إلى أن البوابة البيئية الخليجية هي مصدر ذلك المحتوى وتلك المعلومات.</p>
                            <p> ولا يجوز بأي شكل من الأشكال بيع أو ترخيص أو تأجير أو تعديل أو نسخ أو استنساخ أو إعادة طبع أو تحميل أو إعلان أو نقل أو توزيع أو العرض بصورة علنية أو تحرير أو إنشاء أعمال مشتقة من أي مواد أو محتويات من هذه البوابة للجمهور أو لأغراض تجارية دون الحصول على الموافقة الخطية المسبقة من إدارة البوابة.</p>
                            <p> كما أن الرسومات والصور في هذه البوابة محمية بموجب حقوق النشر، ولا يجوز استنساخها أو استغلالها بأية طريقة كانت دون موافقة خطية مسبقة من إدارة البوابة. يمنع منعا باتا أي تعديل لأي من محتويات البوابة.</p>
                        </Col>
                    </Row>





                </Container>
            </div>
        </div>
    </Layout >



export default CopyrightsPage
